
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiAccountSupervisor, mdiFilter, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { cloneDeep, isEqual } from 'lodash-es'

import { useNotify } from '@/store'

import CommonTableView from '@/components/common/CommonTableView.vue'

import { useFilter } from '@/composables/useFilter'

import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useDeleteProfile, useGetProfiles, useGetProfilesBasic } from '@/api/profile'

import { ProfileOutput } from '@/api/types/profile'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY, TYPES } from '@/views/baseData/views/profiles/types'
import { Rights } from '@/api/types/right'
import { handleError } from '@/utils/handleError'

export default defineComponent({
  name: 'Profiles',
  components: {
    CommonTableView,
    AddEditProfileDialog: () => import('@/views/baseData/views/profiles/components/AddEditProfileDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    ProfilesFilterBar: () => import('@/views/baseData/views/profiles/components/ProfilesFilterBar.vue'),
  },
  setup: (_, { root }) => {
    const TABLE_HEADERS = [
      {
        text: root.$t('baseData.profiles.table.col.title.id'),
        value: 'id',
        sortable: true,
      },
      {
        text: root.$t('baseData.profiles.table.col.title.nr'),
        value: 'nr',
        sortable: true,
      },
      {
        text: root.$t('baseData.profiles.table.col.title.name'),
        value: 'name',
        sortable: true,
      },
      {
        text: root.$t('baseData.profiles.table.col.title.description'),
        value: 'description',
        sortable: true,
      },
      {
        text: root.$t('baseData.profiles.table.col.title.type'),
        value: 'type',
        sortable: true,
      },
      {
        text: root.$t('baseData.profiles.table.col.title.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const { addNotification } = useNotify()

    const isEditMode = ref(false)

    const isAddEditModalOpen = ref(false)

    const profileToEdit = ref<null | ProfileOutput>(null)

    function onProfileEdit(_, { item: profile }: { item: ProfileOutput }): void {
      if (hasSufficientRights(Rights.PROFILE_UPDATE)) {
        isEditMode.value = true

        isAddEditModalOpen.value = true

        profileToEdit.value = { ...profile }
      }
    }

    const isDeleteModalOpen = ref(false)
    const profileToDelete = ref<null | ProfileOutput>(null)

    const { deleteProfile } = useDeleteProfile()

    async function onDelete(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteProfile(profileToDelete.value!.id)

        addNotification({
          text: root.$t(`baseData.profiles.delete.message`) as string,
          type: 'success',
        })

        isDeleteModalOpen.value = false

        debouncedCb.value()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    function onProfileDelete(profile: ProfileOutput): void {
      profileToDelete.value = cloneDeep(profile)

      isDeleteModalOpen.value = true
    }

    function onCloseAddEditDialog(): void {
      isAddEditModalOpen.value = false

      profileToEdit.value = null

      isEditMode.value = false
    }

    const isFilterDropdownOpen = ref(false)

    const { exec: getProfilesBasic, data: profilesBasic, isLoading: isLoadingProfilesBasic } = useGetProfilesBasic()

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (!profilesBasic.value?.length) {
          getProfilesBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.ProfileIds,
        value: null,
        label: root.$t('baseData.profiles.filters.profileIds'),
        items: computed(() => profilesBasic.value ?? []),
        isLoading: computed(() => isLoadingProfilesBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.AssignmentNumber,
        value: null,
        label: root.$t('baseData.profiles.filters.assignmentNumber'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.Name,
        value: null,
        label: root.$t('baseData.profiles.filters.name'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.Type,
        value: null,
        label: root.$t('baseData.profiles.filters.type'),
        items: computed(() => TYPES),
        dataTyp: FilterDataTypes.String,
      },
    ])

    const { exec: getProfiles, data: profiles, isLoading, paginationResponse } = useGetProfiles()

    const totalProfiles = computed(() => paginationResponse.value.totalElements)

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )

    setSort(vuetifyTableOptions, { by: 'name', desc: false })

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getProfiles({ params: { ...paginationParams.value, ...filterFieldsObject } })
    }

    return reactive({
      icons: {
        mdiAccountSupervisor,
        mdiFilter,
        mdiChevronUp,
        mdiChevronDown,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        FILTER_FIELDS,
      },
      state: {
        profiles,
        isLoading,

        isEditMode,
        isAddEditModalOpen,
        profileToEdit,

        isDeleteModalOpen,
        profileToDelete,

        isFilterDropdownOpen,
        vuetifyTableOptions,
        totalProfiles,
      },
      functions: {
        debouncedCb,

        onDelete,

        hasSufficientRights,
      },
      listeners: {
        onProfileEdit,

        onProfileDelete,

        onCloseAddEditDialog,

        onToggleFilterDropdown,
      },
    })
  },
})
