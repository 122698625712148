const TYPES = ['ASSIGNMENT', 'TENDERING']

enum FILTER_FIELD_KEY {
  ProfileIds = 'ids',
  Type = 'type',
  Name = 'name',
  AssignmentNumber = 'nr',
}

export { FILTER_FIELD_KEY, TYPES }
